<template>
  <div style="min-width:1290px;">
    <MyBanner />
    <div class="login-bg">
      <div class="login-box">
        <div style="width:736px;height: 640px;"><img src="./../static/login-img.png" alt="" style="object-fit:cover;width: 100%;height: 100%;"></div>
        <div class="login-form">
          <div class="login-ico">
            <el-icon>
              <Close style="font-size:30px;" />
            </el-icon>
          </div>
          <div class="login-title">欢迎登录</div>
          <div class="login-method">
            <div class="flex" v-for="item in SignInMethod" :key="item.id">
              <div style="cursor: pointer;" :class="{'active':item.id==activeId}" @click="activeIdMed(item.id)">{{item.title}}</div>
              <div v-if="item.id<3" style="margin: 0 15px;">
                <el-icon>
                  <Switch />
                </el-icon>
              </div>
            </div>
          </div>
          <div>
            <UserLogin v-if="activeId==1" />
            <PhoneLogin v-if="activeId==2" />
            <CaLogin v-if="activeId==3" />
          </div>
        </div>
      </div>
    </div>
    <MyFooter style="margin-top:0;" />
  </div>
</template>
<script setup>
import MyBanner from "@/components/MyBanner.vue";
import MyFooter from "@/components/MyFooter.vue";
import UserLogin from "@/components/login/UserLogin.vue";
import PhoneLogin from "@/components/login/PhoneLogin.vue";
import CaLogin from "@/components/login/CaLogin.vue";
import { Close } from '@element-plus/icons-vue'
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
let router = useRouter()
let activeId = ref(1)
let SignInMethod = ref([
  { id: 1, title: '账号登录' },
  { id: 2, title: '手机号登录' },
  { id: 3, title: 'CA登录' }
])
let activeIdMed = (id) => {
  activeId.value = id
}
</script>
<style scoped>
.login-ico {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.active {
  color: rgba(31, 112, 247, 1);
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-bg {
  width: 100%;
  height: calc(100vh - 240px);
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-box {
  width: 1139px;
  border-radius: 5px;
  background: #fff;
  display: flex;
  justify-content: space-between;
}
.login-form {
  width: 403px;
  height: 100%;
  padding: 0 52px;
  box-sizing: border-box;
  background: #fff;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.login-title {
  font-size: 30px;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 20px;
  margin-top: 75px;
}
.login-method {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  display: flex;
  margin-bottom: 44px;
}
</style>
